<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="row">
              <div class="col-md-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header"> Reports</h4>
                  </div>
                  <div class="search_section">
                    <div class="row">
                      <div class="col-md-8 col-sm-12">
                        <div class="form-group mt-1">
                          <select v-model="report" @change="filterReport()" class="form-control">
                            <option value="">--Select Report --</option>
                            <option value="payments">Payment Report</option>
                            <option value="giftcards">Gift Cards Report</option>
                            <option value="redemptionactivities">Redemption Activities Report</option>
                            <option value="redemptionbystore">Redemption By Store</option>
                            <option value="voidvouchers">Voided Gift Cards Report</option>
                            <option value="expiredvouchers">Expired Gift Cards Report</option>
                            <option value="duevouchers"> Upcoming Expiry Gift Cards Report</option>
                            <option value="unusedvouchers">Unused Gift Cards Report</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-12">
                        <button class="btn btn_reserveport_orange mt-1 mr-1" @click="filterReport()"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> search</button>
                        <button class="btn btn_reserveport_orange mt-1" @click="getExportData()" ><i v-if="exportLoading" class="fa fa-spinner fa-spin"></i><i v-if="!exportLoading"  class="fas fa-file-export"></i>Export</button>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <Datepicker placeholder="Start Date" v-model="startDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                      </div>
                      <div class="col-md-2">
                        <Datepicker placeholder="End Date" v-model="endDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                      </div>
                      <div class="col-md-2">
                        <select class="form-control" id=""   v-model="location">
                          <option value="">Select Store</option>
                          <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                        </select>
                      </div>
                      <!-- <div class="col-md-2">
                        <select v-model="limit" class="form-control" >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="">All</option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                
                </div>
                <hr>
              </div>
              <div class="col-md-12">
                <div class="dash_card_body">
                  <div class="summary_body">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="voucher_summary_card cardbox card">
                          <div class="card-body  ">
                            <strong>Total Vouchers Amount</strong> <hr><br>
                            <div class="summary_data_field"  v-if="!loading">
                              <strong>{{ pesapalConfig.currency_code ? pesapalConfig.currency_code : "KES"  }}</strong>
                              <span v-if="summary.all_vouchers.voucher_summary[0] !=undefined">
                                {{ formatPrice(summary.all_vouchers.voucher_summary[0].total_amount) }}
                              </span>
                              <span v-else>
                                {{formatPrice(parseFloat(summary.voucher_bought.total_amount) + parseFloat(summary.voucher_admin_generated.total_amount))}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="voucher_summary_card cardbox card">
                          <div class="card-body  ">
                            <strong>Amount Redeemed</strong> <hr><br>
                            <div class="summary_data_field" v-if="!loading">
                              <strong>{{ pesapalConfig.currency_code ? pesapalConfig.currency_code : "KES"  }}</strong>
                              <span v-if="summary.all_vouchers.voucher_summary[0] !=undefined">
                                {{ formatPrice(summary.all_vouchers.voucher_summary[0].amount_redeemed) }}
                              </span>
                              <span v-else>
                                {{formatPrice(summary.all_vouchers.amount_redeemed)}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="voucher_summary_card cardbox card">
                          <div class="card-body  ">
                            <strong>Pending Redemption</strong> <hr><br>

                            <div class="summary_data_field"  v-if="!loading">
                              <strong>{{ pesapalConfig.currency_code ? pesapalConfig.currency_code : "KES"  }}</strong> 
                              <span v-if="summary.all_vouchers.voucher_summary[0] !=undefined">
                                {{ formatPrice(summary.all_vouchers.voucher_summary[0].total_balance) }}
                              </span>
                              <span v-else>
                                {{formatPrice(parseFloat(summary.voucher_bought.total_amount) + parseFloat(summary.voucher_admin_generated.total_amount) - parseFloat(summary.all_vouchers.amount_redeemed))}}
                              </span> 
                            </div>

                           
                          </div>
                        </div>
                      </div>

                    </div>
                    
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="cardbox card">
                          <div class="card-body  ">
                            <GChart
                              type="PieChart"
                              :data="redemptionStoreChartData"
                              :options="redemptionChartOptions"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="cardbox card">
                          <div class="card-body  ">
                            <GChart
                              type="PieChart"
                              :data="amountStoreChartData"
                              :options="amountChartOptions"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- {{summary.voucher_bought.voucher_summary}}

                      {{ summary.voucher_admin_generated.voucher_summary }} -->
                      <!--ADD CLASSES HERE d-flex align-items-stretch-->
                      <div class="col-lg-4 col-md-4 col-sm-12 mb-3 d-flex align-items-stretch">
                        <div class="cardbox card">
                          <div class="card-body d-flex flex-column">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th>Gift Cards</th>
                                  <th>Units</th>
                                  <th>Amount</th>
                                </thead>
                                <tbody  v-if="!loading">
                                  <tr>
                                    <td>Total Gift Cards Bought:</td>
                                    <td>

                                      <span v-if="summary.voucher_bought.voucher_summary[0] !=undefined">
                                        {{ summary.voucher_bought.voucher_summary[0].total_vouchers }}
                                      </span>
                                      <span v-else>
                                        {{summary.voucher_bought.total_vouchers}}
                                      </span>
                                    </td>
                                    <td>
                                      <span v-if="summary.voucher_bought.voucher_summary[0] !=undefined">
                                        {{ summary.voucher_bought.voucher_summary[0].total_amount }}
                                      </span>
                                      <span v-else>
                                        {{summary.voucher_bought.total_amount}}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total Admin generated Gift Cards</td>
                                    <td>

                                      <span v-if="summary.voucher_admin_generated.voucher_summary[0] !=undefined">
                                        {{ summary.voucher_admin_generated.voucher_summary[0].total_vouchers }}
                                      </span>
                                      <span v-else>
                                        {{summary.voucher_admin_generated.total_vouchers}}
                                      </span>
                                    </td>
                                    <td>
                                      <span v-if="summary.voucher_admin_generated.voucher_summary[0] !=undefined">
                                        {{ summary.voucher_admin_generated.voucher_summary[0].total_amount }}
                                      </span>
                                      <span v-else>
                                        {{summary.voucher_admin_generated.total_amount}}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Total</b></td>
                                    <td>{{parseFloat(summary.voucher_bought.total_vouchers) + parseFloat(summary.voucher_admin_generated.total_vouchers) }}</td>
                                    <td>{{parseFloat(summary.voucher_bought.total_amount) + parseFloat(summary.voucher_admin_generated.total_amount)}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-12 mb-3 d-flex align-items-stretch">
                        <div class="cardbox card">
                          <div class="card-body d-flex flex-column">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th>Gift Card</th>
                                  <th>Amount</th>
                                </thead>
                                <tbody v-if="!loading">
                                  <tr>
                                    <td>Total completed Gift Cards: </td>
                                    <td>{{summary.all_vouchers.completed_voucher_amount}}</td>
                                  </tr>
                                  <tr>
                                    <td>Total Failed Gift Cards:</td>
                                    <td>{{summary.all_vouchers.failed_voucher_amount}}</td>
                                  </tr>
                                  <tr>
                                    <td>Total pending Gift Cards </td>
                                    <td>{{summary.all_vouchers.pending_voucher_amount}}</td>
                                  </tr>
                                  <tr>
                                    <td>Total unpaid Gift Cards </td>
                                    <td>{{summary.all_vouchers.unpaid_voucher_amount}}</td>
                                  </tr>
                                  <tr>
                                    <td><b>Total</b> </td>
                                    <td>{{summary.all_vouchers.total_amount}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-12 mb-3 d-flex align-items-stretch">
                        <div class="cardbox card">
                          <div class="card-body d-flex flex-column">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th>Gift Cards Bought</th>
                                  <th>Amount</th>
                                </thead>
                                <tbody v-if="!loading">
                                  <tr>
                                    <td>Amount Redeemed:</td>
                                    <td>{{summary.all_vouchers.amount_redeemed}}</td>
                                  </tr>
                                  <tr>
                                    <td>Pending Redemption:</td>
                                    <td>{{parseFloat(summary.voucher_bought.total_amount) + parseFloat(summary.voucher_admin_generated.total_amount) - parseFloat(summary.all_vouchers.amount_redeemed)}}</td>
                                  </tr>
                                  <tr>
                                    <td><b>Total Amount</b></td>
                                    <td>{{parseFloat(summary.voucher_bought.total_amount) + parseFloat(summary.voucher_admin_generated.total_amount)}}</td>
                                  </tr>
                                </tbody>
                              </table>                              
                            </div>

                            
                          </div>
                        </div>
                      </div>
                                            
                    </div>
                    <!-- for redemption history detail -->
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="cardbox card">
                          <h4>Active Gift Cards Report Summary </h4>
                          <div class="card-body  ">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th>Date</th>
                                  <th>Voucher Name</th>
                                  <th>Code</th>
                                  <th>Voucher Amount</th> 
                                  <th>Type</th>
                                  <th>Buyer Details</th>	
                                  <th>Recipient Details</th>
                                  <th>Reference</th> 	 	 	 	 	 	 	 
                                </thead>
                                <tbody v-if="!loading">
                                  <tr v-for="voucher in summary.vouchers" :key="voucher.id">
                                    <td>{{formatDate(voucher.created_at)}}</td>
                                    <td>{{voucher.title}}</td>
                                    <td>{{voucher.code}}</td>
                                    <td>
                                      <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                      <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                    </td>
                                    <td>{{voucher.bulk_generated ? 'Bulk Generated' : 'Regular'}}</td>
                                    <td>
                                      <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                      <strong>Email:</strong> {{voucher.customer_email}}<br>
                                      <strong>Phone:</strong> {{voucher.customer_phone}}
                                    </td>
                                    <td>
                                      <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                      <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                      <strong>Phone:</strong> {{voucher.recipient_phone}}
                                    </td>
                                    <td>{{voucher.reference}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- for voucher -->
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="cardbox card">
                          <h4>Redemption Activities Report Summary </h4>
                          <div class="card-body  ">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th>Date</th>
                                  <th>Voucher Name</th>
                                  <th>Code</th>
                                  <th>Amount</th>
                                  <th>Source</th>
                                  <th>Location</th>
                                  <th>Admin Generated</th>
                                  <th>Reference</th>
                                </thead>
                                <tbody v-if="!loading">
                                  <tr v-for="redemption in summary.redemptions" :key="redemption.id">
                                    <td>{{formatDate(redemption.created_at,1)}}</td>
                                    <td>{{redemption.title}}</td>
                                    <td>{{redemption.code}}</td>
                                    <td>{{formatPrice(redemption.amount)}}</td>
                                    <td>{{redemption.source}}</td>
                                    <td>{{redemption.location}}</td>
                                    <td>{{redemption.bulk_generated ? 'Yes' : 'No'}}</td>
                                    <td>{{redemption.reference}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- for payments -->
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="cardbox card">
                          <h4>Payments Report Summary </h4>
                          <div class="card-body  ">
                            <div class="table_section">
                              <table class="table  table-hover">
                                <thead>
                                  <th scope="col">Giftcard Reference</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Phone</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Payment Method</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Date</th>
                                </thead>
                                <tbody v-if="!loading">
                                  <tr v-for="payment in summary.payments" :key="payment.id">
                                    <td>{{payment.voucher_reference}}</td>
                                    <td>{{payment.payer}}</td>
                                    <td>{{payment.payer_email}}</td>
                                    <td>{{payment.phone}}</td>
                                    <td>{{payment.currency ? payment.currency : 'KES'}} {{payment.amount}}</td>
                                    <td>{{payment.method ? payment.method : 'N/A'}}</td>
                                    <td>
                                      <span :style="payment.status == 'COMPLETED'?'color:#149F35;': 'color:#EC850C;'"> <b>{{payment.status}}</b></span> <br> <br>
                                    </td>
                                    <td>
                                      {{formatDate(payment.created_at)}}
                                    </td>
                          
                                  </tr>
                                </tbody>

                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import { GChart } from 'vue-google-charts';

export default {
  components: {
    GChart
  },
    data() {
        return {
          redemptionChartOptions: {
            title: 'Redemption by Store',
            width: 600,
            height: 400,
          },
          amountChartOptions: {
            title: 'Amount by Store',
            width: 600,
            height: 400,
          },
          summary: [],
          exportLoading:false,
          loading: true,
          startDate:this.$store.state.gcardstartDate,
          endDate: this.$store.state.gcardendDate,
          location: this.$store.state.gcardlocation,
          stores: [],
          limit: 50,
          report:'',
          redemptionStoreChartData: [
            ['Store', 'Vouchers']
          ],
          amountStoreChartData: [
             ['Store', 'Vouchers']
          ]
        }
    },
   
    mounted: async function (){
        this.getReportSummary()
        this.getBranches();
        this.startDate = this.$store.state.gcardstartDate ? this.$store.state.gcardstartDate : moment().startOf('month').format('YYYY-MM-DD');
        this.endDate =  this.$store.state.gcardendDate ?  this.$store.state.gcardendDate : moment().format('YYYY-MM-DD');
   

        // google.charts.load('48', {
        //     callback: this.drawChart(),
        //     packages: ['corechart']
        // })
    },
    methods: {
      filterReport(){
        if(this.report !=''){
          this.$router.push({
              name: 'reportDetail',
              params:{
                type:this.report
              }
          });
        }

        this.redemptionStoreChartData = [
            ['Store', 'Vouchers']
          ],
          this.amountStoreChartData = [
             ['Store', 'Vouchers']
          ]

        this.getReportSummary();
      },
      customFormatter(date) {
        return moment(date).format('YYYY-MM-DD');
      },
      drawChart () {
        // 
      },
      async getReportSummary(){
        this.loading = true;
        const loader = document.getElementById('main-loader');
        loader.style.display = 'block';
        const payload = {
          'start_date': this.startDate,
          'end_date': this.endDate,
          'location': this.location,
          'limit': this.limit
        }
        this.$store.commit('updateGvReportSearchData', payload)
        try{
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          let url = `${this.dashBaseUrl}/report-summary/${this.merchantID}`;
          url = new URL(url);
          if(this.startDate !==''){
            url.searchParams.set('start_date',moment(this.startDate).format('YYYY-MM-DD'));
          }
          if(this.endDate !== ''){
             url.searchParams.set('end_date',moment(this.endDate).format('YYYY-MM-DD'));
          }
          if(this.location !==''){
            url.searchParams.set('store', this.location);
          }
          url.searchParams.set('limit', this.limit);
          const response = await this.axios.get(url,config);
          if(response.status == 200){
            this.loading = false;
            this.summary = response.data.data
            const redemptionByStore =  this.summary.all_vouchers.redemption_by_store;
            for(let x=0; x < redemptionByStore.length; x++){
              const redemptionStoreArr = [redemptionByStore[x].reportname, redemptionByStore[x].total_vouchers_redeemed];
              const amountStoreArr = [redemptionByStore[x].reportname, redemptionByStore[x].amount_redeemed];
              this.amountStoreChartData.push(amountStoreArr);
              this.redemptionStoreChartData.push(redemptionStoreArr);
            }

            loader.style.display = 'none';
          }  
        }catch(error){
          this.loading  = false;
          loader.style.display = 'none';
          if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
          }
        }
      },
       async getExportData(){
        this.exportLoading = true;
        try{
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          let url = `${this.dashBaseUrl}/report-summary-details/${this.merchantID}`;
          url = new URL(url);
          if(this.startDate !==''){
            url.searchParams.set('start_date',moment(this.startDate).format('YYYY-MM-DD') );
          }
          if(this.endDate !== ''){
             url.searchParams.set('end_date', moment(this.endDate).format('YYYY-MM-DD'));
          }
          if(this.location !==''){
            url.searchParams.set('store', this.location);
          }
          url.searchParams.set('limit', 100000);
          const response = await this.axios.get(url,config);
          if(response.status == 200){
            this.exportLoading = false;
            const redemptions = response.data.data.redemptions;
            const vouchers = response.data.data.gift_vouchers;
            const payments = response.data.data.payments;
            const expiredVouchers = response.data.data.expired_vouchers
            const voidedVouchers = response.data.data.voided_vouchers

            const vouchersDetails = [];
            let amountTotal = 0;
            let balanceTotal = 0;
            for (const voucher of Object.values(vouchers)) {
              amountTotal = amountTotal + parseFloat(voucher.amount)
              balanceTotal = balanceTotal + parseFloat(voucher.balance)
              vouchersDetails.push({
                'Date': this.formatDate(voucher.created_at),
                'Voucher Name':voucher.title,
                'code': voucher.code,
                'Amount': this.formatPrice(voucher.amount),
                'Balance':this.formatPrice(voucher.balance),
                'Payment status':voucher.payment_status,
                'Type':voucher.bulk_generated ? 'Bulk Generated' : 'Regular',
                'Buyer Details': 'Name: ' + voucher.customer_first_name +' ' +voucher.customer_last_name+ ' Email: '+voucher.customer_email+ ' Phone: '+voucher.customer_phone,
                'Recepient Details':'Name: ' + voucher.recipient_first_name +' ' +voucher.recipient_last_name+ ' Email: '+voucher.recipient_email+ ' Phone: '+voucher.recipient_email,
                'Reference':voucher.reference
              })
            }
            vouchersDetails.push({
                'Date': 'Total',
                'Voucher Name':'',
                'code': '',
                'Amount': this.formatPrice(amountTotal),
                'Balance':this.formatPrice(balanceTotal),
                'Payment status': '',
                'Type':'',
                'Buyer Details': '',
                'Recepient Details':'',
                'Reference':''
              });
              const redemptionsDetails = []
              for (const redemption of Object.values(redemptions)) {
                redemptionsDetails.push({
                  'Date': this.formatDate(redemption.created_at),
                  'Voucher Name':redemption.title,
                  'Code':redemption.code,
                  'Amount': this.formatPrice(redemption.amount),
                  'Location': redemption.location,
                  'Source': redemption.source,
                  'Type': redemption.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Reference': redemption.reference,
                  'Redemption Reference':redemption.merchant_reference
                })
              }
              const paymentDetails = [];
              for (const payment of Object.values(payments)) {
                paymentDetails.push({
                  'Name': payment.payer,
                  'Payer Email': payment.payer_email,
                  'Phone': payment.phone,
                  'Amount': this.formatPrice(payment.amount),
                  'Status': payment.status,
                  'Method': payment.method,
                  'Date': this.formatDate(payment.created_at),
                  'Reference': payment.voucher_reference
                });
              }
              const voidedVouchersDetails = [];
              for (const voidedVoucher of Object.values(voidedVouchers)) {
                voidedVouchersDetails.push({
                  'Date':this.formatDate(voidedVoucher.created_at),
                  'Voucher Name':voidedVoucher.title,
                  'Code':voidedVoucher.code,
                  'Amount': this.formatPrice(voidedVoucher.amount),
                  'Balance': this.formatPrice(voidedVoucher.balance),
                  'Type': voidedVoucher.bulk_generated ? 'Bulk generated' : 'Regular',
                  'Buyer Details': 'Name: ' + voidedVoucher.customer_first_name +' ' +voidedVoucher.customer_last_name+ ' Email: '+voidedVoucher.customer_email+ ' Phone: '+voidedVoucher.customer_phone,
                  'Recepient Details':'Name: ' + voidedVoucher.recipient_first_name +' ' +voidedVoucher.recipient_last_name+ ' Email: '+voidedVoucher.recipient_email+ ' Phone: '+voidedVoucher.recipient_email,
                  'Reference': voidedVoucher.reference,
                  'Void Reason': voidedVoucher.void_reason,
                  'Void Remarks':voidedVoucher.void_remarks
                })
              }

              const expiredVouchersDetails = [];
              for (const expiredVoucher of Object.values(expiredVouchers)) {
                expiredVouchersDetails.push({
                   'Date':this.formatDate(expiredVoucher.created_at),
                  'Voucher Name':expiredVoucher.title,
                  'Code':expiredVoucher.code,
                  'Amount': this.formatPrice(expiredVoucher.amount),
                  'Balance': this.formatPrice(expiredVoucher.balance),
                  'Type': expiredVoucher.bulk_generated ? 'Bulk generated' : 'Regular',
                  'Buyer Details': 'Name: ' + expiredVoucher.customer_first_name +' ' +expiredVoucher.customer_last_name+ ' Email: '+expiredVoucher.customer_email+ ' Phone: '+expiredVoucher.customer_phone,
                  'Recepient Details':'Name: ' + expiredVoucher.recipient_first_name +' ' +expiredVoucher.recipient_last_name+ ' Email: '+expiredVoucher.recipient_email+ ' Phone: '+expiredVoucher.recipient_email,
                  'Reference': expiredVoucher.reference,
                  'Expiry Date': expiredVoucher.expiry_date,
                  'Expiry Period':expiredVoucher.expiry_period
                })
              }
              const fileName =  Date.now()+ '_GiftCardsReport'
              this.downloadExcel(fileName,vouchersDetails,paymentDetails,
                                redemptionsDetails,voidedVouchersDetails,
                                expiredVouchersDetails)
          }  
        }catch(error){
          this.loading  = false;
          if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
          }
        }
      },
      async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id="+ this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })
      },
       
    },
     mixins: [ReusableDataMixins,ReusableFunctionMixins]
}
</script>
